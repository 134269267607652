.create-pipeline-container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.create-pipeline-h1 {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.create-pipeline-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  justify-content: space-between;
  background-color: white;
  margin: 30px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #d04a02 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #d04a02 !important;
}

.item-container {
  background-color: #8f9291;
  color: white;
  list-style: none;
  font-weight: bold;
  border-radius: 10px;
  padding: 15px;
  border: 2px solid lightgray;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: white !important;
  box-shadow: 0px 0px 1px #fafafa;
  background-color: #e8630a !important;
  padding: 6px 16px;
  text-decoration: none;
  border-radius: 4px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.empty-pipeline-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin: 30px;
  gap: 20px;
}

.create-pipeline-h1 {
  padding: 0;
}