.create-pipeline-container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.create-pipeline-h1 {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.create-pipeline-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  width: 100%;
  justify-content: space-between;
  background-color: #fafafa;
  margin: 30px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #d04a02 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #d04a02 !important;
}

.css-1bw0nnu-MuiStep-root {
  padding-right: 0 !important;
}

.css-j5w0w9-MuiStepConnector-root {
  border-top: 1.5px solid #d04a02;
  display: flex;
  width: 1%;
}

.css-1hv8oq8-MuiStepLabel-label {
  font-size: 0.8rem !important;
}

.css-ff1ts6-MuiSvgIcon-root-MuiStepIcon-root {
  width: 1.3rem !important;
  height: 1.3rem !important;
}
