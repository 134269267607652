.file-div {
  gap: 10px;
  display: flex;
  flex-direction: row;
}

.file-div>div {
  width: 20%;
}

.add-file-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-file-button {
  height: 35px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.add-button-div .copy-file-button-select {
  min-width: 130px;
  max-width: 200px;
  width: 100% !important;
}

.add-button-div .Mui-disabled {
  cursor: not-allowed !important;
}

.add-file-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.add-file-info-text {
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.add-button-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.col-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.trans-div {
  display: flex;
  padding: 5px;
  flex-direction: column;
  gap: 20px;
}

.main-file-div {
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 0.5px solid black;
}

.react-multi-email-div {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.step-add-button {
  display: flex;
  margin-top: 10px;
}

.hide-step-add-button {
  visibility: hidden;
}

.last-step-add-button {
  display: none;
}

.dropdown-form-control.MuiFormControl-root {
  width: 200px;
}

.dropdown-select {
  height: 35px;
}

.info-text {
  font-style: italic;
  padding: 5px;
  text-align: center;
  /* Bootstrap's info color */
}

#file-label.MuiInputLabel-outlined {
  transform: translate(12px, 7px) scale(1);
}

#file-label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -9px) scale(0.75);
}