.css-lb7u2y-MuiButtonBase-root-MuiButton-root {
  background-color: #d04a02 !important;
}

.css-lb7u2y-MuiButtonBase-root-MuiButton-root:hover {
  text-decoration: none;
  background-color: #d04a02 !important;
}

.link-style {
  text-decoration: none;
  color: black;
}

.img-style {
  margin-top: 10;
  width: 40%;
}
