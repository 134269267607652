th {
  font-family: 'HelveticaNeue-Bold' !important;
}

.fixedWidth {
  max-width: 165px;
  word-wrap: break-word;
}

.overflow {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow:hover {
  overflow: visible;
}

.overflow:hover span {
  position: relative;
}
