.add-condition-btn {
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.condition-div {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
