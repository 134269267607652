.mft-data-source-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.div-style {
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr;
}

.add-file-button-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-file-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
