.email-template-title {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 30px 0 10px 0;
}

.email-template-note {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  font-style: italic;
  margin: 5px 0 0 0;
}

.editorClassName {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  border: 0.1rem solid rgb(209, 209, 209);
  min-height: 200px;
  padding: 10px;
}

.rdw-suggestion-dropdown {
  min-width: 180px !important;
}
